.cad-viewer__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
}

.cad-viewer__wrapper.cad-viewer__wrapper-active {
    opacity: 1;
    visibility: visible;
}

.cad-viewer__iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 80%;
    height: 80%;
    border: none;
    border-radius: 6px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}

.cad-viewer__iframe.cad-viewer-inline {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    width: auto;
    height: auto;
    box-shadow: none;
    border-radius: none;
}

.cad-viewer__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
}