@import "./colors";
@import "./border-radius.scss";

$font-weight-regular: 400 !important;
$font-weight-semibold: 600 !important;
$font-weight-bold: bold !important;

@mixin elementQuery($selector, $lowerBound: false, $upperBound: false) {
    @if $lowerBound != false and $upperBound != false {
        @element '#{$selector}' and (min-width: #{$lowerBound}) and (max-width: #{$upperBound}) {
            @content;
        }
    }

    @if $lowerBound != false and $upperBound == false {
        @element '#{$selector}' and (min-width: #{$lowerBound}) {
            @content;
        }
    }

    @if $lowerBound == false and $upperBound != false {
        @element '#{$selector}' and (max-width: #{$upperBound}) {
            @content;
        }
    }
}

%comment-shared {
    // font-size: 13px;
    color: #7f8c8d;
    font-style: italic;
    line-height: 1.4em;
    max-height: 1.4em;
    overflow: hidden;
    position: relative;
    overflow-wrap: anywhere;

    &.expanded {
        max-height: none;
        white-space: pre-line;

        .truncate-button {
            display: inline;
            position: initial;
            padding-left: 8px;
        }
    }

    .truncate-button {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 1.4em;
        padding-left: 96px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
        cursor: pointer;
        color: #009ee2;
        text-decoration: underline;

        &:hover {
            color: #0075a8;
        }
    }
}

@include elementQuery('#overview-baskets-container, .configuration-basket--expandable', $upperBound: 511px) {
    %comment-shared {
        word-break: break-all;

        .truncate-button {
            padding-left: 64px;
        }
    }
}

%clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

%button {
    border-radius: border-radius(button);
    border: 1px solid;
    transition: color 0.2s, background-color 0.2s;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.9em;
    font-weight: 600 !important;

    padding: 0px 8px;

    i {
        font-size: 1em;
        margin-right: 8px;
    }
}

@mixin testButton($foregroundColor, $backgroundColor, $borderColor) {
    @extend %button;

    background-color: $backgroundColor;
    color: $foregroundColor;
    border-color: $borderColor;

    &:hover {
        background-color: $borderColor;
    }
}

%button-primary {
    @include testButton(
        $foregroundColor: color(accent, foreground),
        $backgroundColor: color(accent),
        $borderColor:     color(accent, alt)
    );
}

%button-primary-minimalist {
    background: transparent;
    color: color(accent);
    border-color: color(accent);

    &:hover {
        background-color: color(accent);
        color: color(accent, foreground);
    }
}

%button-secondary {
    background: black;
    color: red;
}

%button-positive {
    @include testButton(
        $foregroundColor: color(positive, foreground),
        $backgroundColor: color(positive),
        $borderColor:     color(positive, alt)
    );
}

%button-warning {
    background: color(warning);
    border-color: color(warning, alt);
    color: color(warning, foreground);

    &:hover {
        background: color(warning, alt);
    }
}

%button-danger {
    background: color(danger);
    border-color: color(danger, alt);
    color: color(danger, foreground);

    &:hover {
        background: color(danger, alt);
    }
}

%button-special {
    background: color(special);
    border-color: color(special, alt);
    color: color(special, foreground);

    &:hover {
        background: color(special, alt);
    }
}

%reset-styles {
    // Reset any previously applied styling
    input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea {
        all: initial;
        font-family: inherit;
    }
}

#vbf-content {
    @extend %reset-styles;

    * {
        font-size: 1em;
    }

    .vbf-hidden {
        display: none !important;
        visibility: hidden;
        opacity: 0;
    }

    .vbf-button--primary {
        @extend %button;
        @extend %button-primary;
    }
    
    .vbf-button {
        @extend %button;
    
        &.vbf-button-primary {
            @extend %button-primary;
    
            &.vbf-button-minimalist {
                background: transparent;
                border-color: color(accent);
                color: color(accent);
            }
        }
    
        &.vbf-button-secondary {
            @extend %button-secondary;
        }
    
        &.vbf-button-positive {
            @extend %button-positive;
        }
    
        &.vbf-button-warning {
            @extend %button-warning;
        }
    
        &.vbf-button-danger {
            @extend %button-danger;
        }
    }
    
    .text-vanBeekBlue {
        color: color(accent);
    }
    
    .configurator-page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 50px;
        line-height: 1.125;
        position: relative;
        z-index: 199;
    
        &.title-container {
            .title {
                .primary {
                    font-weight: bold !important;
                    font-size: 1.412em;
                }
    
                .secondary {
                    font-weight: 600 !important;
                    // font-size: 1em;
                    margin-top: 2px;

                    * {
                        font-weight: 600 !important;
                    }
                }
            }
    
            &.title-container--centered {
                .title .primary {
                    margin-bottom: 8px;
                }
            }
        }
    
        .actions {
            $action-spacing: 32px;
    
            display: flex;
            flex-shrink: 0;
            flex-grow: 0;
            flex-direction: row-reverse;
            align-items: center;
    
            margin-left: $action-spacing !important;
    
            > * + * {
                margin-right: $action-spacing !important;
            }
        }
    }

    .vbf-form__wrapper.vbf-form--center {
        // position: absolute;
        // width: 100%;
        // height: 100%;
        // left: 0;
        // top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .vbf-form.vbf-form--center {
        text-align: center;
        max-width: calc(100vw - 32px);
    }
    
    .vbf-form h2 {
        margin: 32px 0px;
    }
    
    .vbf-form .inputlabel {
        margin-right: 8px;
    }
    
    .vbf-form.vbf-form--center .inputfield {
        width: 100%;
        max-width: 320px;
        margin: auto;
    }
    
    .vbf-form .inputfield + .inputfield,
    .vbf-form .component-message + .inputfield {
        margin-top: 24px;
    }
    
    .vbf-form .inputfield + .inputfield:last-child,
    .vbf-form .component-message + .inputfield:last-child {
        margin-top: 32px;
    }
    
    .vbf-form input:not([type="checkbox"]) {
        width: 100%;
    }
    
    .vbf-form input[type="checkbox"] {
        -webkit-appearance: checkbox;
    }
    
    .vbf-form input[type="checkbox"] + label {
        display: inline;
        text-align: right;
    }
    
    .vbf-form .inputfield.submitfield {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        margin-bottom: 24px;
    }
    
    .vbf-form .submitfield button[type="submit"] {
        box-sizing: border-box;
    }
    
    .vbf-form .submitfield:not(.submitfield--dual-buttons) button[type="submit"] {
        width: 100%;
    }
    
    .vbf-form .submitfield.submitfield--dual-buttons button[type="submit"] {
        width: calc(50% - 8px);
    }
    
    .vbf-form .vbf-form__error {
        background-color: #EA2027;
    }
    
    .vbf-form .inputfield label {
        // font-size: 12px;
        font-weight: 600 !important;
        text-transform: uppercase;
        display: block;
        text-align: left;
        margin-bottom: 8px;
    }
    
    .vbf-form button {
        padding: 8px 12px;
        // font-size: 14px;
        border-radius: 3px;
        border: 1px solid #0075a8;
        background-color: #009ee2;
        color: white;
        cursor: pointer;
        transition: background-color 0.2s, color 0.2s;
    }
    
    .vbf-form button i {
        // font-size: 15px;
        margin-right: 8px;
    }
    
    .vbf-form button:hover {
        background-color: #0075a8;
        color: white;
    }
    
    .vbf-form button[type="submit"].alternative {
        background-color: transparent;
        color: #0075a8;
    }
    
    .vbf-form button[disabled] {
        border-color: gray;
        background-color: gray;
        cursor: default;
    }
    
    .vbf-form button[type="submit"].alternative[disabled] {
        border-color: gray;
        color: gray;
    }
    
    .vbf-form button[type="submit"].alternative:not([disabled]):hover {
        background-color: #0075a8;
        color: white;
    }
    
    .vbf-form .component-message {
        display: inline-flex;
        margin-top: 32px;
    }
    
    .component-message.confirm {
        background-color: #A3CB38;
    }
    
    .vbf-form .component-header {
        position: relative;
    }
    
    .vbf-form .component-message {
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 8px 10px;
        border-radius: 3px;
        color: white;
        font-weight: bold !important;
        flex-direction: row;
        align-items: center;
        width: auto;
    
        display: inline-flex;
    }
    
    .vbf-form .component-message.active + .component-message.active {
        margin-top: 8px;
    }
    
    .vbf-form .component-header:after {
        content: "";
        display: table;
        clear: both;
    }
    
    .vbf-form .component-message i {
        // font-size: 16px;
        margin-right: 8px;
    }
    
    .vbf-form .component-message[data-level='warning'] {
        background-color: #F79F1F;
    }
    
    .vbf-form .component-message[data-level='error'] {
        background-color: #EA2027;
    }
    
    .vbf-form .title {
        margin-bottom: 32px;
        line-height: normal;
    }
    
    .vbf-form .title .primary {
        // font-size: 32px;
        font-weight: bold !important;
        color: #009ee2;
    }
    
    .vbf-form .title .secondary {
        // font-size: 24px;
        font-weight: 600 !important;
    }
    
    .vbf-form .button__minimalist {
        display: block;
        max-width: 320px;
        margin: auto;
        text-align: right;
        margin-top: 8px;
        // font-size: 14px;
    }

    input.input-style-minimalist {
        background-color: none;
        border: none;
        border-bottom: 2px solid gray;
        border-radius: 0;
        opacity: 0.75;
        padding: 8px 4px;
        font-size: 14px;
        background-color: transparent;
        box-sizing: border-box;
        margin: 0;
        transition: opacity 0.2s, border-color 0.2s;
    }
    
    input.input-style-minimalist:hover,
    input.input-style-minimalist:focus {
        opacity: 1;
    }
    
    input.input-style-minimalist:focus {
        border-color: #009ee2;
        outline: none;
    }
    
    input.input-style-minimalist[invalid] {
        border-bottom: 2px solid #EA2027;
    }

    .vbf-form {
        .title > * {
            line-height: 1.5;
        }
    
        .title .primary {
            font-size: 2em;
            font-weight: bold !important;
        }
    
        .title .secondary {
            font-size: 1.25em;
            font-weight: bold !important;
        }
    
        form {
            font-size: 0.8em;
        }
    }
}

@element '.configurator-page-header' and (max-width: 639px) {
    #vbf-content {
        .configurator-page-header {
            flex-direction: column;
        }
        
        .configurator-page-header .title {
            text-align: center;
            width: 100%;
        }
        
        .configurator-page-header .actions {
            justify-content: space-between;
            width: 100%;
            margin-left: 0px !important;
        }
        
        .configurator-page-header > * + * {
            margin-top: 32px;
        }
        
        .configurator-page-header .actions .overview-searchbar {
            flex-grow: 1;
        }
    }
}

@element '.configurator-page-header' and (max-width: 511px) {
    #vbf-content {
        padding-top: 24px;

        .configurator-page-header {
            padding-bottom: 24px;
        }
    
        .configurator-page-header.title-container .title {
            text-align: left;
        }
    
        .configurator-page-header .actions {
            display: none;
        }
    }
}

@media screen and (max-width: 39.9375em) {
    #breadcrumbs a, #breadcrumbs a:visited, .breadcrumb_last {
        font-size: 12px !important;
    }
}