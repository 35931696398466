html {
    --vbf-color-accent: #009ee2;
    --vbf-color-accent-alt: #0075a8;
    --vbf-color-accent-foreground: white;

    --vbf-color-positive: #2ecc71;
    --vbf-color-positive-alt: #27ae60;
    --vbf-color-positive-foreground: white;

    --vbf-color-warning: #f79f1f;
    --vbf-color-warning-alt: #ee5a24;
    --vbf-color-warning-foreground: white;

    --vbf-color-danger: #ea2027;
    --vbf-color-danger-alt: #c0392b;
    --vbf-color-danger-foreground: white;

    --vbf-color-special: #9b59b6;
    --vbf-color-special-alt: #8e44ad;
    --vbf-color-special-foreground: white;
}

@media (prefers-color-scheme: dark) {
    html {
        // Override color variables for dark mode here
    }
}

$colors: (
    accent: (
        base: var(--vbf-color-accent),
        alt: var(--vbf-color-accent-alt),
        foreground: var(--vbf-color-accent-foreground)
    ),

    positive: (
        base: var(--vbf-color-positive),
        alt: var(--vbf-color-positive-alt),
        foreground: var(--vbf-color-positive-foreground)
    ),

    warning: (
        base: var(--vbf-color-warning),
        alt: var(--vbf-color-warning-alt),
        foreground: var(--vbf-color-warning-foreground)
    ),

    danger: (
        base: var(--vbf-color-danger),
        alt: var(--vbf-color-danger-alt),
        foreground: var(--vbf-color-danger-foreground)
    ),

    special: (
        base: var(--vbf-color-special),
        alt: var(--vbf-color-special-alt),
        foreground: var(--vbf-color-special-foreground)
    )
);

@function color($base, $variant: base) {
    $color: map-get(map-get($colors, $base), $variant);
    @return $color;
}
//END colors