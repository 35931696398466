@import "./common.scss";

%configuration-action-wrapper {
    .configuration-basket__configuration__action {
        height: 32px;
        min-width: 108px;
        padding: 0px 8px;
        display: flex;
        justify-content: left;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
        border-radius: 3px;
        position: relative;
        white-space: nowrap;
        // font-size: 14px;
    
        border: 1px solid rgba(0,117,168,0.3);
        background-color: transparent;
        color: #009ee2;
        transition: background-color 0.2s, border-color 0.2s, color 0.2s, border-radius 0.2s, box-shadow 0.2s;

        i {
            font-size: 15px;
            margin-right: 8px;
            flex-basis: 25%;
            flex-shrink: 1;
        }

        &:hover, &[data-action="menu"].active {
            background-color: #0075a8;
            border-color: #0075a8;
            color: white;
        }

        &[data-action="menu"].active {
            border-radius: 3px 3px 0px 0px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
        }

        &[data-action="delete"] {
            color: #eb4d4b;

            &:hover {
                background-color: #b33939;
                color: white;
            }
        }

        + .configuration-basket__configuration__action {
            margin-left: 8px;
        }
    }
}

#vbf-content {
    .configuration-basket__configuration {
        font-size: 0.9em;

        min-height: 48px;
        padding: 8px 0px;
        border-bottom: 1px solid lightgray;
        display: flex;
        align-items: center;
        display: grid;
        grid-template-columns: 1fr auto auto;
        grid-template-rows: auto;
    
        &:hover {
            z-index: 2;
        }

        .configuration-basket__configuration__icons {
            min-width: 1px;
            min-height: 18px;
            text-align: right;

            .configuration-basket__configuration__icon {
                font-size: 16px;
                margin-top: 2px;
                display: none;

                &:not(:first-child) {
                    margin-left: 4px;
                }

                &.active {
                    display: inline-block;
                }

                &.error {
                    color: #EA2027;
                }

                &.warning {
                    color: #F79F1F;
                }
            }
        }
    
        .configuration-basket__configuration__left-wrapper {
            flex-grow: 1;
            flex-shrink: 1;
            align-items: center;
            display: flex;
            flex-direction: row;
    
            .configuration-basket__configuration__preview {
                height: 100%;
                height: 48px;
                width: 48px;
                background-color: #EDF4F7;
                border-radius: 3px;
                //border: 1px solid #0075a8;
                box-sizing: border-box;
                margin-right: 16px;
                overflow: hidden;
                flex-shrink: 0;
            
                cursor: pointer;
                position: relative;
    
                > * {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
    
                .configuration-basket__configuration__preview__overlay {
                    background-color: rgba(255, 255, 255, 0.8);
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0.2s, opacity 0.2s;
                }
    
                .configuration-basket__configuration__preview__icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                
                    // font-size: 2em;
                    font-size: 24px;
                    color: #009ee2;
                    text-shadow: 0 0 1px #000000;
                
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.2s, visibility 0.2s;
    
                    &.active {
                        visibility: visible;
                        opacity: 0.5;
    
                        &.keep-visible {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
    
                &:hover {
                    .configuration-basket__configuration__preview__icon.active,
                    .configuration-basket__configuration__preview__overlay.active {
                        visibility: visible;
                        opacity: 1;
                    }
    
                    .configuration-basket__configuration__preview__icon-small.active {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
                
                .configuration-basket__configuration__preview__icon-small {
                    top: auto;
                    left: auto;
                    bottom: 0px;
                    right: 0px;
                    // font-size: 16px;
                    font-size: 16px;
                    width: 24px;
                    height: 24px;
                }
    
                .configuration-basket__configuration__preview__icon-generating.active {
                    visibility: hidden;
                    opacity: 0;
                }
    
                .configuration-basket__configuration__preview__image {
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.2s, visibility 0.2s;
                }
                
                .configuration-basket__configuration__preview__image[src] {
                    opacity: 1;
                    visibility: visible;
                }
            }
    
            .configuration-basket__configuration__text-wrapper {
                flex-grow: 1;
                flex-shrink: 1;
                margin-right: 32px;

                display: grid;
                grid-template-columns: 1fr auto;
                grid-template-rows: auto auto;

                .configuration-basket__configuration__icons {
                    display: none;
                    margin-left: 16px;
                }
    
                .configuration-basket__configuration__title {
                    font-weight: 600 !important;
                    grid-column: 1;
                    grid-row: 1;
                    //font-size: 0.9em;
                }
    
                .configuration-basket__configuration__comment {
                    @extend %comment-shared;
                    grid-column: 1 / span 2;
                    grid-row: 2;
                    //font-size: 0.8em;
                }
            }
        }
    
        .configuration-basket__configuration__right-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 32px;
    
            .configuration-basket__configuration__amount {
                display: flex;
                flex-direction: row;
                flex-grow: 0;
                flex-shrink: 0;
    
                input,
                .configuration-basket__configuration__amount-button {
                    width: 32px;
                    height: 32px;
                    box-sizing: border-box;
                }
    
                input {
                    -moz-appearance: textfield;
                    border: 1px solid;
                    border-color: rgba(0,117,168,0.3);
                    background-color: transparent;
                    text-align: center;
                    // font-size: 14px;
                    font-size: 1em;
                    border-radius: 0;
                    transition: border-color 0.2s;
    
                    &:focus {
                        border-color: #0075a8;
                    }
    
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
    
                .configuration-basket__configuration__amount-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid;
                    border-color: rgba(0,117,168,0.3);
                    color: #009ee2;
                    background-color: transparent;
                    cursor: pointer;
                    transition: color 0.2s, background-color 0.2s, border-color 0.2s;
    
                    &:hover {
                        background-color: #0075a8;
                        border-color: #0075a8;
                        color: white;
                    }
    
                    &[data-action="add"] {
                        border-radius: 0px 3px 3px 0px;
                        border-left: none;
                    }
    
                    &[data-action="subtract"] {
                        border-radius: 3px 0px 0px 3px;
                        border-right: none;
                    }
                }
    
                .configuration-basket__configuration__amount-static {
                    display: none;
                    height: 32px;
                    min-width: 32px;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
    
            .configuration-basket__configuration__properties {
                display: flex;
                flex-shrink: 0;
                flex-grow: 0;
                min-width: 80px;
                flex-direction: column;
                justify-content: right;
                margin-left: 32px;

                .configuration-basket__configuration__price {
                    text-align: right;
                }
            }
        }
    
        .configuration-basket__configuration__actions {
            @extend %configuration-action-wrapper;
    
            flex-shrink: 0;
            flex-grow: 0;
            display: flex;
            flex-wrap: wrap;
            height: 32px;
        }
    }
    
    // Read-only styling
    .configuration-basket.vbf-readonly .configuration-basket__configuration {
        .configuration-basket__configuration__amount input,
        .configuration-basket__configuration__amount-button {
            display: none;
        }
    
        .configuration-basket__configuration__amount-static {
            display: flex;
        }
    }
}

.configuration-basket__configuration__action-menu__wrapper {
    position: absolute;
    z-index: 99999;
    font-size: 1em;
    min-width: 120px;
    
    * {
        font-size: 1em;
    }

    .configuration-basket__configuration__action-menu {
        @extend %configuration-action-wrapper;

        position: absolute;
        background-color: white;
        box-sizing: border-box;
    
        border-top: none;
        border-radius: 0px 0px 3px 3px;
        opacity: 0;
        overflow: hidden;
    
        transition: opacity 0.2s, box-shadow 0.2s;
    
        top: 0;
        left: 0;
        width: 100%;

        .configuration-basket__configuration__action {
            margin-left: 0px;
            border: none;
            border-radius: 0px;
            font-size: 0.9em;
        }

        .configuration-basket__configuration__action-menu__spacer {
            margin: 0px 8px;
            height: 1px;
            background-color: rgba(0,117,168,0.3);
        }
    }
    
    .configuration-basket__configuration__action-menu__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        visibility: hidden;
        opacity: 0;
        
        transition: visibility 0.3s, opacity 0.3s;
    }

    &.active .configuration-basket__configuration__action-menu {
        opacity: 1;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); 
    }

    .configuration-basket__configuration__action-menu__list:not([data-primary]) {
        display: none;
    }
}

@include elementQuery('#overview-baskets-container, .configuration-basket--expandable', $upperBound: 900px) {
    #vbf-content {
        .configuration-basket__configuration {
            padding: 16px 0px;
            grid-template-columns: auto 1fr;
            grid-template-rows: 1fr auto;
            grid-gap: 16px;
            column-gap: 8px;
    
            .configuration-basket__configuration__left-wrapper {
                .configuration-basket__configuration__title {
                    margin-right: 0px;
                }
            }

            .configuration-basket__configuration__left-wrapper {
                grid-column: 1 / span 2;

                .configuration-basket__configuration__text-wrapper {
                    margin-right: 0px;

                    .configuration-basket__configuration__icons {
                        display: block;
                    }
                }
            }
    
            .configuration-basket__configuration__actions {
                grid-row: 2;
                grid-column: 1;
            }
    
            .configuration-basket__configuration__right-wrapper {
                margin-left: 0px;
                grid-row: 2;
                grid-column: 2;
                grid-gap: 8px;
                display: grid;

                grid-template-columns: 1fr auto;

                .configuration-basket__configuration__amount {
                    grid-column: 1;
                    grid-row: 1;
                    align-self: center;
                }

                .configuration-basket__configuration__properties {
                    align-self: center;
                    grid-column: 2;
                    grid-row: 1;
                    min-width: auto;
                    margin-left: 0px;

                    .configuration-basket__configuration__price {
                        min-width: 80px;
                    }

                    .configuration-basket__configuration__icons {
                        display: none;
                    }
                }
            }
        }
    }
}

// @include elementQuery('#overview-baskets-container, .configuration-basket--expandable', $lowerBound: 361px, $upperBound: 540px) {
//     #vbf-content {
//         .configuration-basket__configuration {
//             .configuration-basket__configuration__actions {
//                 .configuration-basket__configuration__action {
//                     min-width: auto;
//                     align-content: center;
//                     justify-content: center;

//                     width: 32px;
//                     height: 32px;

//                     & > i {
//                         margin-right: 0px;
//                     }

//                     & > div {
//                         display: none;
//                     }
//                 }
//             }
//         }
//     }
// }

@include elementQuery('#overview-baskets-container', $upperBound: 500px) {
    #vbf-content {
        .configuration-basket__configuration {
            grid-template-columns: auto 1fr;

            .configuration-basket__configuration__actions {
                height: auto;
                max-height: auto;
                border: 1px solid rgba(0, 117, 168, 0.3);
                border-radius: 3px;
                overflow: hidden;
                box-sizing: border-box;
                grid-row: 2;
                grid-column: 1;
                max-width: 200px;

                display: grid;
    
                .configuration-basket__configuration__action {
                    width: 100%;
                    height: 32px;
                    border: none;
                    border-radius: 0;
    
                    &[data-action="menu"].active {
                        border-radius: 0;
                    }
    
                    & + .configuration-basket__configuration__action {
                        margin-left: 0px;
                        border-top: 1px solid rgba(0, 117, 168, 0.3);
                    }
                }
            }

            .configuration-basket__configuration__right-wrapper {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr auto;
                align-self: stretch;
                grid-gap: 0px;

                .configuration-basket__configuration__amount {
                    justify-self: end;
                    grid-column: 1;
                    grid-row: 2;
                }

                .configuration-basket__configuration__properties {
                    .configuration-basket__configuration__price {
                        min-width: auto;
                    }

                    grid-column: 1;
                    grid-row: 1;
                }
            }
        }
    }
}

@include elementQuery('#vbf-content', $upperBound: 767px) {
    #vbf-content {
        .configuration-basket__configuration {
            .configuration-basket__configuration__actions {
                .configuration-basket__configuration__action[data-action="menu"].active {
                    border-radius: 3px;
                }
            }
        }
    }

    .configuration-basket__configuration__action-menu__wrapper {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        position: fixed;

        .configuration-basket__configuration__action-menu {
            position: absolute;
            bottom: 4vw !important;
            top: auto !important;
            width: 92vw !important;
            border-radius: none;
            background-color: transparent;
            box-shadow: none !important;
            opacity: 1;

            max-width: 512px !important;
            left: 50% !important;
            transform: translateX(-50%) translateY(125%);
            transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1);

            .configuration-basket__configuration__action-menu__list {
                background-color: white;
                border-radius: 6px;
                overflow: hidden;

                &:not([data-primary]) {
                    display: block;
                }

                & + .configuration-basket__configuration__action-menu__list {
                    margin-top: 4vw;
                }
            }

            .configuration-basket__configuration__action {
                height: 48px;
                padding: 0;

                &[data-action="menu"].active {
                    border-radius: 3px;
                    box-shadow: none;
                }

                i {
                    flex-basis: 64px;
                    margin: 0;
                    font-size: 20px;
                }

                > div {
                    font-size: 1.2em;
                }
            }

            .configuration-basket__configuration__action-menu__spacer {
                margin-left: 64px !important;
                margin-right: 16px !important;
            }
        }

        &.active {
            .configuration-basket__configuration__action-menu__background {
                opacity: 1;
                visibility: visible;
            }

            .configuration-basket__configuration__action-menu {
                transform: translateY(0) translateX(-50%);
            }
        }
    }
}