@import "./common";

#vbf-content {
    .configuration-basket {
        //border-radius: 6px;
        // font-size: 16px;
    
        //BEGIN header
        .configuration-basket__header {
            display: flex;
            flex-direction: row;
            flex-shrink: 0;
            height: 42px;
            color: white;
            max-height: 48px;
    
            .configuration-basket__title {
                display: flex;
                align-items: center;
                padding: 0px 12px;
                flex-grow: 1;
                transition: visibility 0.3s, opacity 0.3s;
                font-weight: 600 !important;
                //border-radius: 6px 0px 0px 0px;
                padding-right: 0px;
                min-width: 0;
                font-size: 1.1em;
    
                .configuration-basket__title-name {
                    line-height: 24px;
                    overflow: hidden;
                    max-height: 48px;
                    flex-shrink: 1;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
    
                .configuration-basket__title-quotation {
                    // font-size: 14px;
                    font-size: 0.9em;
                    margin-top: 2px;
                    white-space: nowrap;
                    margin-left: 12px;
                }
            }
        }
    
        &:not(.active) .configuration-basket__header .configuration-basket__title-quotation {
            color: #636e72;
        }
        //END header
    
        //BEGIN content
        .configuration-basket__content {
            padding: 0px 16px;
            // font-size: 14px;
            position: relative;
            flex-grow: 1;
    
            .configuration-basket__empty {
                font-size: 0.9em;
                text-align: center;
                display: none;
                min-height: 64px;
                align-items: center;
                justify-content: center;
                font-weight: 600 !important;
            }
        }
    
        &.empty .configuration-basket__empty {
            display: flex;
        }
    
        .configuration-basket__info-wrapper {
            border-top: 2px solid #0075a8;
            padding: 16px 0px;
            color: #0075a8;
    
            .configuration-basket__subtotal {
                @extend %clearfix;
                
                // font-size: 15px;
                font-weight: 600 !important;
    
                span:first-child {
                    float: left;
                }
    
                span:last-child {
                    float: right;
                }
            }
    
            .configuration-basket__comment-wrapper {
                justify-content: space-between;
                display: none;
    
                &.active {
                    display: flex;
                }
    
                .configuration-basket__comment-title {
                    // font-size: 15px;
                    font-weight: 600 !important;
                    padding-right: 8px;
                }
    
                .configuration-basket__comment {
                    @extend %comment-shared;
                    margin-top: 2px;
                }
            }
        }
    
        &:not(.empty) .configuration-basket__comment-wrapper {
            padding-top: 8px;
        }
    
        &.empty {
            .configuration-basket__subtotal,
            &:not(.has-comment) .configuration-basket__info-wrapper {
                display: none;
            }
        }
        //END content
    }
}